import { useState, useEffect, useRef } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";
import notification from "../assets/notification.png";
import profile from "../assets/profile.png";
import customer from "../assets/customer.png";
import { BsBarChartLineFill, BsGlobe, BsPeople, BsPower } from "react-icons/bs";
import { Drawer, Badge } from "antd";
import { IoIosArrowForward } from "react-icons/io";
import { FiBell, FiCreditCard, FiUsers } from "react-icons/fi";
import { BsCreditCard } from "react-icons/bs";
import { BsCardText } from "react-icons/bs";
import { BsCurrencyDollar } from "react-icons/bs";
import { FiSend } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  AiOutlineFormatPainter,
  AiOutlineLineChart,
  AiOutlinePhone,
  AiOutlinePieChart,
} from "react-icons/ai";
import { HiHashtag } from "react-icons/hi";
import {
  BiBook,
  BiCheckShield,
  BiPurchaseTag,
  BiUpload,
  BiUser,
  BiUserCheck,
  BiUserX,
} from "react-icons/bi";
import { GrTransaction } from "react-icons/gr";
import { FaRegCalendarTimes } from "react-icons/fa";
import { RxCardStack, RxDashboard, RxGear } from "react-icons/rx";
import { GoLocation, GoMail } from "react-icons/go";
import usePostData from "../hooks/usePostData";
import useGetData from "../hooks/useGetData";

const Dashboard = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const location = useLocation();
  const currentUrl = location.pathname;

  console.log(currentUrl);

  useEffect(() => {
    if (currentUrl === "/") {
      token ? navigate("/admin") : navigate("/login");
    }
  }, [currentUrl, token, navigate]);

  // making post request for notification search
  let notificationData = JSON.stringify({
    length: 10,
    start: 0,
    orderBy: "id",
    orderDir: "asc",
    search: "",
  });
  const { postData: notiData } = usePostData(
    "/users/account/notifications",
    notificationData
  );
  console.log(notiData, "notiData");
  const { getData: langData } = useGetData("/users/account");
  const { getData: DashboardData } = useGetData("/users/dashboard");

  console.log("lang data", langData);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const handleDropdownClick = (dropdown) => {
    if (dropdown === activeDropdown) {
      setActiveDropdown(null); // if the same dropdown is clicked again, turn it off
    } else {
      setActiveDropdown(dropdown); // turn on the selected dropdown and turn off others
    }
  };

  // handling drawer movement..
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="bg-[#F9F9F9]">
      {/* topbar */}

      {/*only for mobile navigation */}
      <Drawer
        className="relative h-full  "
        title=""
        placement="left"
        onClose={onClose}
        open={open}
      >
        <div className="space-y-[19px] pl-[150px] absolute top-0 left-0 pt-[20px]">
          <div onClick={onClose}>
            <NavLink to="/admin" className=" mb-[20px]">
              <img src={logo} alt="" className="cursor-pointer w-[90px]" />
            </NavLink>
          </div>

          <div className=" bg-white lg:flex px-[16px] pt-[0px] flex-col gap-[8px]  absolute  left-[120px]  md:basis-[15%] ">
            {/* dashboard */}
            <div
              onClick={onClose}
              className="flex items-center gap-[16px] cc nav_link rounded-[6px]  pl-[18px] py-[6.5px] max-w-[223px] cursor-pointer group"
            >
              <RxDashboard
                size={17}
                className=" text-[#625f6e]   group-hover:text-white "
              />
              <NavLink to="/admin">
                <p className="text-[#625f6e]  text-[14px] leading-[16.41px]  group-hover:text-[#F5F5F5] w-[130px] ">
                  Dashboard
                </p>
              </NavLink>
            </div>

            {/*-------- customer--------- */}
            <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
              <NavLink
                to="#"
                className="w-full"
                style={({ isActive }) => {
                  return { backgroundColor: isActive ? "" : "" };
                }}
              >
                <div
                  className="flex items-center gap-[16px] py-[6.5px] nav_link  w-full pl-[18px] rounded-[6px] group"
                  onClick={() => handleDropdownClick("customer")}
                >
                  <div className="flex items-center group gap-[16px]">
                    {/* <img src={customer} alt="" className="group-hover:invert" /> */}
                    <BsPeople
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Customer
                    </p>
                  </div>
                  {activeDropdown === "customer" ? (
                    // <img src={arrow_right} alt="" className={"rotate-90"} />
                    <IoIosArrowForward
                      size={13}
                      className="rotate-90 text-[#625f6e]  group-hover:text-white "
                    />
                  ) : (
                    // <img src={arrow_right} alt="" className={"rotate-0"} />
                    <IoIosArrowForward
                      size={13}
                      className="rotate-0 text-[#625f6e]  group-hover:text-white "
                    />
                  )}
                </div>
              </NavLink>
              {activeDropdown === "customer" && (
                <div className="w-full transition duration-300 ease-out flex flex-col gap-[8px] bg-[#E5F0F7]">
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <BsPeople
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/customers">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Customers
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <FiCreditCard
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/subscriptions">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Subscriptions
                      </p>
                    </NavLink>
                  </div>
                </div>
              )}
            </div>

            {/* ----------plan--------- */}
            <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
              <NavLink
                to="#"
                className="w-full"
                style={({ isActive }) => {
                  return { backgroundColor: isActive ? "" : "" };
                }}
              >
                <div
                  className="flex items-center gap-[16px] py-[6.5px] nav_link  w-full pl-[18px] rounded-[6px] group"
                  onClick={() => handleDropdownClick("plan")}
                >
                  <div className="flex items-center group gap-[16px]">
                    {/* <img src={plan} alt="" className="group-hover:invert" /> */}
                    <BsCreditCard
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Plan
                    </p>
                  </div>
                  {activeDropdown === "plan" ? (
                    // <img src={arrow_right} alt="" className={"rotate-90"} />
                    <IoIosArrowForward
                      size={13}
                      className="rotate-90 text-[#625f6e]  group-hover:text-white "
                    />
                  ) : (
                    // <img src={arrow_right} alt="" className={"rotate-0"} />
                    <IoIosArrowForward
                      size={13}
                      className="rotate-0 text-[#625f6e]  group-hover:text-white "
                    />
                  )}
                </div>
              </NavLink>
              {activeDropdown === "plan" && (
                <div className="w-full transition duration-300 ease-out flex flex-col gap-[8px] bg-[#E5F0F7]">
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    {/* <img src={customer} alt="" className="group-hover:invert" /> */}
                    <BsCardText
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/plans">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Plans
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    {/* <img src={customer} alt="" className="group-hover:invert" /> */}
                    <BsCurrencyDollar
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/currencies">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Currencies
                      </p>
                    </NavLink>
                  </div>
                </div>
              )}
            </div>

            {/*--------------- sending-------------------- */}
            <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
              <NavLink
                to="#"
                className="w-full"
                style={({ isActive }) => {
                  return { backgroundColor: isActive ? "" : "" };
                }}
              >
                <div
                  className="flex items-center gap-[16px] py-[6.5px] cc nav_link  w-full pl-[18px] rounded-[6px] group"
                  onClick={() => handleDropdownClick("sending")}
                >
                  <div className="flex items-center gap-[16px]">
                    <FiSend
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Sending
                    </p>
                  </div>

                  {activeDropdown === "sending" ? (
                    <IoIosArrowForward
                      size={13}
                      className="rotate-90 text-[#625f6e]  group-hover:text-white "
                    />
                  ) : (
                    <IoIosArrowForward
                      size={13}
                      className="rotate-0 text-[#625f6e]  group-hover:text-white "
                    />
                  )}
                </div>
              </NavLink>
              {activeDropdown === "sending" && (
                <div className="w-full transition duration-300 ease-out flex flex-col gap-[8px] bg-[#E5F0F7]">
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <FiSend
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/sending ">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Sending Servers
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    {/* <img src={customer} alt="" className="group-hover:invert" /> */}
                    <BiBook
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/senderid">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Sender ID
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    {/* <img src={customer} alt="" className="group-hover:invert" /> */}
                    <AiOutlinePhone
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/phone-numbers">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Numbers
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    {/* <img src={customer} alt="" className="group-hover:invert" /> */}
                    <HiHashtag
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/keywords">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Keywords
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    {/* <img src={customer} alt="" className="group-hover:invert" /> */}
                    <BiPurchaseTag
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/tags">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Template Tags
                      </p>
                    </NavLink>
                  </div>
                </div>
              )}
            </div>

            {/* ------------------security------------- */}
            <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
              <NavLink
                to="#"
                className="w-full"
                style={({ isActive }) => {
                  return { backgroundColor: isActive ? "" : "" };
                }}
              >
                <div
                  className="flex items-center gap-[16px] py-[6.5px] nav_link  w-full pl-[18px] rounded-[6px] group"
                  onClick={() => handleDropdownClick("security")}
                >
                  <div className="flex items-center gap-[16px]">
                    <BiCheckShield
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />

                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Security
                    </p>
                  </div>

                  {activeDropdown === "security" ? (
                    <IoIosArrowForward
                      size={13}
                      className="rotate-90 text-[#625f6e]  group-hover:text-white "
                    />
                  ) : (
                    <IoIosArrowForward
                      size={13}
                      className="rotate-0 text-[#625f6e]  group-hover:text-white "
                    />
                  )}
                </div>
              </NavLink>
              {activeDropdown === "security" && (
                <div className="w-full transition duration-300 ease-out flex flex-col gap-[8px] bg-[#E5F0F7]">
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <BiUserX
                      size={18}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/blacklists">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Blacklist
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    {/* <img src={security} alt="" className="group-hover:invert" /> */}
                    <FaRegCalendarTimes
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/spam-word">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Spam World
                      </p>
                    </NavLink>
                  </div>
                </div>
              )}
            </div>

            {/* ----------------administrator--------------------- */}
            <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
              <NavLink
                to="#"
                className="w-full"
                style={({ isActive }) => {
                  return { backgroundColor: isActive ? "" : "" };
                }}
              >
                <div
                  className="flex items-center gap-[16px] py-[6.5px] nav_link  w-full pl-[18px] rounded-[6px] group"
                  onClick={() => handleDropdownClick("administrator")}
                >
                  <div className="flex items-center gap-[16px]">
                    <BiUser
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Administrator
                    </p>
                  </div>
                  {activeDropdown === "administrator" ? (
                    <IoIosArrowForward
                      size={13}
                      className="rotate-90 text-[#625f6e]  group-hover:text-white "
                    />
                  ) : (
                    <IoIosArrowForward
                      size={13}
                      className="rotate-0 text-[#625f6e]  group-hover:text-white "
                    />
                  )}{" "}
                </div>
              </NavLink>
              {activeDropdown === "administrator" && (
                <div className="w-full transition duration-300 ease-out flex flex-col gap-[8px] bg-[#E5F0F7]">
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <FiUsers
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/administrators">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Administrators
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <BiUserCheck
                      size={18}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/roles">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Admin Roles
                      </p>
                    </NavLink>
                  </div>
                </div>
              )}
            </div>

            {/* ---------------settings-------------- */}
            <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
              <NavLink
                to="#"
                className="w-full"
                style={({ isActive }) => {
                  return { backgroundColor: isActive ? "" : "" };
                }}
              >
                <div
                  className="flex items-center gap-[16px] py-[6.5px] nav_link  w-full pl-[18px] rounded-[6px] group"
                  onClick={() => handleDropdownClick("settings")}
                >
                  <div className="flex items-center gap-[16px]">
                    <RxGear
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Settings
                    </p>
                  </div>
                  {activeDropdown === "settings" ? (
                    <IoIosArrowForward
                      size={13}
                      className="rotate-90 text-[#625f6e]  group-hover:text-white "
                    />
                  ) : (
                    <IoIosArrowForward
                      size={13}
                      className="rotate-0 text-[#625f6e]  group-hover:text-white "
                    />
                  )}
                </div>
              </NavLink>
              {activeDropdown === "settings" && (
                <div className="w-full transition duration-300 ease-out flex flex-col gap-[8px] bg-[#E5F0F7]">
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <RxGear
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/settings">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        All Settings
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <GoLocation
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/countries">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Countries
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <BsGlobe
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/languages">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Language
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <RxCardStack
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/payment-gateways">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Payment Gateways
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <GoMail
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/email-templates">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Email Templates
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <BiUpload
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/update-application">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Update Application
                      </p>
                    </NavLink>
                  </div>
                </div>
              )}
            </div>

            {/*--------------------- reports--------------------- */}
            <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
              <NavLink
                to="#"
                className="w-full"
                style={({ isActive }) => {
                  return { backgroundColor: isActive ? "" : "" };
                }}
              >
                <div
                  className="flex items-center gap-[16px] py-[6.5px] nav_link  w-full pl-[18px] rounded-[6px] group"
                  onClick={() => handleDropdownClick("reports")}
                >
                  <div className="flex items-center gap-[16px]">
                    <BsBarChartLineFill
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Reports
                    </p>
                  </div>

                  {activeDropdown === "reports" ? (
                    <IoIosArrowForward
                      size={13}
                      className="rotate-90 text-[#625f6e]  group-hover:text-white "
                    />
                  ) : (
                    <IoIosArrowForward
                      size={13}
                      className="rotate-0 text-[#625f6e]  group-hover:text-white "
                    />
                  )}
                </div>
              </NavLink>
              {activeDropdown === "reports" && (
                <div className="w-full transition duration-300 ease-out flex flex-col gap-[8px] bg-[#E5F0F7]">
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <AiOutlinePieChart
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/invoices">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        All Invoices
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <AiOutlineLineChart
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="/admin/reports">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        SMS History
                      </p>
                    </NavLink>
                  </div>
                  <div
                    onClick={onClose}
                    className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]"
                  >
                    <AiOutlineLineChart
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <NavLink to="">
                      <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                        Transactions
                      </p>
                    </NavLink>
                  </div>
                </div>
              )}
            </div>

            {/* ------------------theme Customizer------------------- */}
            <div className="flex items-center flex-col gap-[16px]  rounded-[6px]    w-[223px] cursor-pointer ">
              <NavLink
                to="/admin/customizer"
                className="w-full"
                style={({ isActive }) => {
                  return { backgroundColor: isActive ? "" : "" };
                }}
              >
                <div className="flex items-center gap-[16px] py-[6.5px] nav_link  w-full pl-[18px] rounded-[6px] group">
                  <div
                    onClick={onClose}
                    className="flex items-center gap-[16px]"
                  >
                    <AiOutlineFormatPainter
                      size={17}
                      className=" text-[#625f6e]   group-hover:text-white "
                    />
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Theme Customizer
                    </p>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </Drawer>

      <div className="md:pl-[55px] px-[15px] md:px-0 pt-[10px] pb-[8px] border bg-white border-b-[#D9D9D9] flex items-center justify-between md:pr-[40px]  sticky z-10 top-0 w-full ">
        <div
          className=" border-[#EDEDED] rounded-[2px] h-[28px] w-[28px] flex items-center justify-center cursor-pointer  md:flex lg:hidden mr-[10px]"
          onClick={showDrawer}
        >
          <svg
            width="22"
            height="21"
            viewBox="0 0 27 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="27" height="3" rx="1.5" fill="#97A6B7" />
            <rect y="9" width="27" height="3" rx="1.5" fill="#97A6B7" />
            <rect y="18" width="27" height="3" rx="1.5" fill="#97A6B7" />
          </svg>
        </div>
        <NavLink to="/admin">
          <img
            src={logo}
            alt=""
            className="cursor-pointer w-[70%] md:w-full "
          />
        </NavLink>
        <div className="flex items-center md:gap-[24px] gap-[12px]">
          {/* =================language section=================== */}
          <div
            className="flex items-center relative cursor-pointer"
            onClick={() => handleDropdownClick("language")}
          >
            <div className="relative cursor-pointer">
              {/* <img src={online} alt="" className='absolute bottom-[1px] right-[13px]' /> */}
              {/* {(langData && langData.languages[0]?.name) ?? ""} */}
              English
            </div>

            {/* {activeDropdown === "language" && (
              <div className="absolute top-[50px] py-4 right-[-2px] bg-white  flex flex-col  border border-[#EDEDED] shadow-md rounded-[5px]">
                {langData?.languages.map((val) => (
                  <div
                    key={val.id}
                    className="flex items-center justify-between gap-[2px] cursor-pointer"
                  >
                    <p className="text-[#625f6e] w-full py-2 px-6  hover:bg-[#EEEDFD] hover:text-[var(--accent-orange)]  form-label-size leading-[14.52px] font-normal">
                      {val.name}
                      {val.code}
                    </p>
                  </div>
                ))}
              </div>
            )} */}
          </div>

          {/*=============== notification section ======================== */}
          <div
            className="flex items-center relative px-3 cursor-pointer"
            onClick={() => handleDropdownClick("notification")}
          >
            <Badge offset={[7, 0]} count={notiData?.length}>
              <div className="relative cursor-pointer">
                <FiBell size={20} />
              </div>
            </Badge>

            {activeDropdown === "notification" && (
              <div className="absolute top-[48px] h-[55dvh] overflow-y-hidden  right-[-2px] bg-white py-[5px] w-[400px] flex flex-col gap-[10px] border border-[#EDEDED] shadow-md rounded-[5px]">
                <div className="flex items-center w-[380px] fixed z-10 bg-white  px-4 py-[1.28rem] justify-between gap-[6px] border-b-[2px] border-[#F2F1F6]  cursor-pointer">
                  <p className="text-[var(--accent-gray)] p-1   text-[1rem] font-[500] leading-[14.52px] ">
                    Notifications
                  </p>

                  <p className="text-[#F08D3B] form-label-size py-[0.3rem] px-[0.4rem] bg-[#EEEDFD] rounded-[1rem]  leading-[14.52px] font-bold ">
                    {notiData?.length} New
                  </p>
                </div>
                <div className="h-[56px]"></div>
                <div className="flex-1 overflow-auto">
                  {notiData?.map((val: any) => (
                    <div
                      key={val.uid}
                      className="flex relative  items-center justify-between gap-[6px] cursor-pointer"
                    >
                      <div className="flex border-b-[1px] border-[#F2F1F6]  justify-around w-full align-middle items-center py-[0.8rem]  ">
                        <div className="w-[80%] flex gap-4 pl-4 justify-start items-center ">
                          <div className="bg-[#FCEAEA] flex justify-center items-center p-[0.5rem] rounded-[50%] ">
                            <BiUserCheck size={25} className="text-[#EA5455]" />
                          </div>

                          <div className="flex  flex-col gap-1">
                            <p className="text-[#6E6B7B]  w-[180px] whitespace-nowrap overflow-hidden text-[0.85rem] leading-[14.52px] font-medium ">
                              {`New ${val.notification_type} Notification`}{" "}
                              <br />
                            </p>

                            <p className="text-[var(--accent-gray)] w-[170px] truncate form-label-size ">
                              {val.message}
                            </p>
                          </div>
                        </div>

                        <div className="w-[20%] text-center  ">
                          <p className="text-[var(--accent-gray)]  form-label-size leading-[14.52px] font-normal">
                            1 week ago
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="sticky border-t-[1px] border-[#F2F1F6] bottom-2 p-4 ">
                  <button
                    onClick={() => navigate("/admin/account?tab=notification")}
                    className="bg-[#F08B38] w-full text-center text-white px-[21px] py-[8px] rounded-md  "
                  >
                    Read all notification
                  </button>
                </div>
              </div>
            )}
          </div>

          <div
            className="flex items-center relative cursor-pointer"
            onClick={() => handleDropdownClick("profile")}
          >
            <div className="relative cursor-pointer">
              <img
                src={profile}
                alt=""
                className="md:mr-[12px] mr-[3px] w-[35px] h-[35px] cursor-pointer"
              />
              {/* <img src={online} alt="" className='absolute bottom-[1px] right-[13px]' /> */}
            </div>

            <p className="md:mr-[7px] text-[14px] leading-[14.06px] font-medium text-[#625f6e]  cursor-pointer">
              {langData?.user?.first_name + " " + langData?.user?.last_name}
            </p>

            {activeDropdown === "profile" && (
              <div className="absolute top-[50px] right-[-2px] bg-white   w-[144px] flex flex-col  border border-[#EDEDED] rounded-[2px]">
                {/* <div className="flex items-center gap-[6px] cursor-pointer">
                  <img src={profile} alt="" />
                  <p className="text-[#625f6e]  text-[14px] leading-[14.52px] font-normal">
                    Profile
                  </p>
                </div> */}
                <div className="flex justify-center items-center py-4 gap-[6px] cursor-pointer">
                  <p className="text-[var(--accent-gray)] cursor-default  text-[14px] leading-[14.52px] ">
                    Manage Profile
                  </p>
                </div>

                <div
                  onClick={() => navigate("/admin/account")}
                  className="flex items-center group  hover:bg-[#EEEDFD] hover:text-[var(--accent-orange)] py-4  pl-4 border-y-[1px]  gap-[6px] cursor-pointer"
                >
                  <BiUser className="text-[var(--accent-gray)] group-hover:text-[var(--accent-orange)] " />
                  <p className="text-[var(--accent-gray)] group-hover:bg-[#EEEDFD] group-hover:text-[var(--accent-orange)]  text-[14px] leading-[14.52px] font-normal">
                    <NavLink to="/admin/account">profile</NavLink>
                  </p>
                </div>

                <div
                  className="flex items-center group  hover:bg-[#EEEDFD] hover:text-[var(--accent-orange)] py-4 pl-4 gap-[6px] cursor-pointer"
                  onClick={async () => {
                    await localStorage.removeItem("token");
                    navigate("/login");
                  }}
                >
                  <BsPower className="text-[var(--accent-gray)] group-hover:text-[var(--accent-orange)]  " />
                  <p className="text-[var(--accent-gray)]  group-hover:bg-[#EEEDFD] group-hover:text-[var(--accent-orange)]   text-[14px] leading-[14.52px] font-normal">
                    Logout
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* -----------------------------main section----------------------- */}
      <div className="flex bg-[#f9f9f9] gap-[20px]">
        {/* ----------------sidebar-Navigation--------------------------- */}
        <div className="overflow-y-auto ggg bg-white lg:flex  pt-[20px] flex-col h-full gap-[8px] fixed hidden  md:basis-[15%] ">
          {/* dashboard */}
          <div className="flex items-center gap-[16px] cc nav_link rounded-[6px]  pl-[18px] py-[6.5px] max-w-[223px] cursor-pointer group">
            {/* <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#5F6368"
                d="M2.38889 17.5C1.86944 17.5 1.42461 17.3152 1.05439 16.9456C0.684796 16.5754 0.5 16.1306 0.5 15.6111V2.38889C0.5 1.86944 0.684796 1.42461 1.05439 1.05439C1.42461 0.684796 1.86944 0.5 2.38889 0.5H15.6111C16.1306 0.5 16.5754 0.684796 16.9456 1.05439C17.3152 1.42461 17.5 1.86944 17.5 2.38889V15.6111C17.5 16.1306 17.3152 16.5754 16.9456 16.9456C16.5754 17.3152 16.1306 17.5 15.6111 17.5H2.38889ZM2.38889 15.6111H8.05556V2.38889H2.38889V15.6111ZM9.94444 15.6111H15.6111V9H9.94444V15.6111ZM9.94444 7.11111H15.6111V2.38889H9.94444V7.11111Z"
              />
            </svg> */}
            <RxDashboard
              size={17}
              className=" text-[#625f6e]   group-hover:text-white "
            />
            <NavLink to="/admin">
              <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px]">
                Dashboard
              </p>
            </NavLink>
          </div>

          {/* -----------------------customer----------------------- */}
          <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
            <NavLink
              to="#"
              className="w-full"
              style={({ isActive }) => {
                return { backgroundColor: isActive ? "" : "" };
              }}
            >
              <div
                className="flex items-center  py-[6.5px] nav_link  w-full pl-[18px] rounded-[6px] group"
                onClick={() => handleDropdownClick("customers")}
              >
                <div className="flex items-center gap-[16px]">
                  <BsPeople
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                    Customer
                  </p>
                </div>

                {activeDropdown === "customers" ? (
                  //
                  <IoIosArrowForward
                    size={15}
                    className="rotate-90 text-[#625f6e]  group-hover:text-white "
                  />
                ) : (
                  //
                  <IoIosArrowForward
                    size={15}
                    className="rotate-0 text-[#625f6e]  group-hover:text-white "
                  />
                )}
              </div>
            </NavLink>
            {activeDropdown === "customers" && (
              <div className="w-full transition duration-300 ease-out flex flex-col gap-[8px] bg-[#E5F0F7]">
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <BsPeople
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/customers">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Customers
                    </p>
                  </NavLink>
                </div>
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <FiCreditCard
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/subscriptions">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Subscriptions
                    </p>
                  </NavLink>
                </div>
              </div>
            )}
          </div>

          {/*------------------- plan-------------- */}
          <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
            <NavLink
              to="#"
              className="w-full"
              style={({ isActive }) => {
                return { backgroundColor: isActive ? "" : "" };
              }}
            >
              <div
                className="flex items-center  py-[6.5px] nav_link  w-full pl-[18px] rounded-[6px] group"
                onClick={() => handleDropdownClick("plan")}
              >
                <div className="flex items-center gap-[16px]">
                  <BsCreditCard
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                    Plan
                  </p>
                </div>
                {activeDropdown === "plan" ? (
                  <IoIosArrowForward
                    size={15}
                    className="rotate-90 text-[#625f6e]  group-hover:text-white "
                  />
                ) : (
                  <IoIosArrowForward
                    size={15}
                    className="rotate-0 text-[#625f6e]  group-hover:text-white "
                  />
                )}{" "}
              </div>
            </NavLink>
            {activeDropdown === "plan" && (
              <div className="w-full transition duration-300 ease-out flex flex-col gap-[8px] bg-[#E5F0F7]">
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <BsCardText
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/plans">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Plans
                    </p>
                  </NavLink>
                </div>
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <BsCurrencyDollar
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/currencies">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Currencies
                    </p>
                  </NavLink>
                </div>
              </div>
            )}
          </div>
          {/*-------------------- sending--------------- */}
          <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
            <NavLink
              to="#"
              className="w-full"
              style={({ isActive }) => {
                return { backgroundColor: isActive ? "" : "" };
              }}
            >
              <div
                className="flex items-center  py-[6.5px] cc nav_link  w-full pl-[18px] rounded-[6px] group"
                onClick={() => handleDropdownClick("sending")}
              >
                <div className="flex items-center gap-[16px]">
                  <FiSend
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                    Sending
                  </p>
                </div>
                {activeDropdown === "sending" ? (
                  <IoIosArrowForward
                    size={15}
                    className="rotate-90 text-[#625f6e]  group-hover:text-white "
                  />
                ) : (
                  <IoIosArrowForward
                    size={15}
                    className="rotate-0 text-[#625f6e]  group-hover:text-white "
                  />
                )}{" "}
              </div>
            </NavLink>
            {activeDropdown === "sending" && (
              <div className="w-full transition duration-300 ease-out flex flex-col gap-[8px] bg-[#E5F0F7]">
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <FiSend
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/sending ">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Sending Servers
                    </p>
                  </NavLink>
                </div>
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <BiBook
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/senderid">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Sender ID
                    </p>
                  </NavLink>
                </div>
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <AiOutlinePhone
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/phone-numbers">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Numbers
                    </p>
                  </NavLink>
                </div>
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <HiHashtag
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/keywords">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Keywords
                    </p>
                  </NavLink>
                </div>
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <BiPurchaseTag
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/tags">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Template Tags
                    </p>
                  </NavLink>
                </div>
              </div>
            )}
          </div>

          {/* --------------------security------------------ */}
          <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
            <NavLink
              to="#"
              className="w-full"
              style={({ isActive }) => {
                return { backgroundColor: isActive ? "" : "" };
              }}
            >
              <div
                className="flex items-center  py-[6.5px] nav_link  w-full pl-[18px] rounded-[6px] group"
                onClick={() => handleDropdownClick("security")}
              >
                <div className="flex items-center gap-[16px]">
                  <BiCheckShield
                    size={18}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                    Security
                  </p>
                </div>
                {activeDropdown === "security" ? (
                  <IoIosArrowForward
                    size={15}
                    className="rotate-90 text-[#625f6e]  group-hover:text-white "
                  />
                ) : (
                  <IoIosArrowForward
                    size={15}
                    className="rotate-0 text-[#625f6e]  group-hover:text-white "
                  />
                )}
              </div>
            </NavLink>
            {activeDropdown === "security" && (
              <div className="w-full transition duration-300 ease-out flex flex-col gap-[8px] bg-[#E5F0F7]">
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <BiUserX
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/blacklists">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Blacklist
                    </p>
                  </NavLink>
                </div>
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <FaRegCalendarTimes
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/spam-word">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Spam World
                    </p>
                  </NavLink>
                </div>
              </div>
            )}
          </div>

          {/*------------------- administrator---------------- */}
          <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
            <NavLink
              to="#"
              className="w-full"
              style={({ isActive }) => {
                return { backgroundColor: isActive ? "" : "" };
              }}
            >
              <div
                className="flex items-center  py-[6.5px] nav_link  w-full pl-[18px] rounded-[6px] group"
                onClick={() => handleDropdownClick("administrator")}
              >
                <div className="flex items-center gap-[16px]">
                  <BiUser
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                    Administrator
                  </p>
                </div>
                {activeDropdown === "administrator" ? (
                  <IoIosArrowForward
                    size={15}
                    className="rotate-90 text-[#625f6e]  group-hover:text-white "
                  />
                ) : (
                  <IoIosArrowForward
                    size={15}
                    className="rotate-0 text-[#625f6e]  group-hover:text-white "
                  />
                )}
              </div>
            </NavLink>
            {activeDropdown === "administrator" && (
              <div className="w-full transition duration-300 ease-out flex flex-col gap-[12px] bg-[#E5F0F7]">
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <FiUsers
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/administrators">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Administrator
                    </p>
                  </NavLink>
                </div>
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <BiUserCheck
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/roles">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Admin Roles
                    </p>
                  </NavLink>
                </div>
              </div>
            )}
          </div>

          {/* -----------settings ------------*/}
          <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
            <NavLink
              to="#"
              className="w-full"
              style={({ isActive }) => {
                return { backgroundColor: isActive ? "" : "" };
              }}
            >
              <div
                className="flex items-center  py-[6.5px] nav_link  w-full pl-[18px] rounded-[6px] group"
                onClick={() => handleDropdownClick("settings")}
              >
                <div className="flex items-center gap-[16px]">
                  <RxGear
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                    Settings
                  </p>
                </div>
                {activeDropdown === "settings" ? (
                  <IoIosArrowForward
                    size={15}
                    className="rotate-90 text-[#625f6e]  group-hover:text-white "
                  />
                ) : (
                  <IoIosArrowForward
                    size={15}
                    className="rotate-0 text-[#625f6e]  group-hover:text-white "
                  />
                )}
              </div>
            </NavLink>
            {activeDropdown === "settings" && (
              <div className="w-full transition duration-300 ease-out flex flex-col gap-[8px] bg-[#E5F0F7]">
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <RxGear
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/settings">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      All settings
                    </p>
                  </NavLink>
                </div>
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <GoLocation
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/countries">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Countries
                    </p>
                  </NavLink>
                </div>
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <BsGlobe
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/languages">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Language
                    </p>
                  </NavLink>
                </div>
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <RxCardStack
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/payment-gateways">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Payment Gateways
                    </p>
                  </NavLink>
                </div>
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <GoMail
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/email-templates">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Email Templates
                    </p>
                  </NavLink>
                </div>
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <BiUpload
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/update-application">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Update Application
                    </p>
                  </NavLink>
                </div>
              </div>
            )}
          </div>

          {/*----------------------- reports-------------------- */}
          <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
            <NavLink
              to="#"
              className="w-full"
              style={({ isActive }) => {
                return { backgroundColor: isActive ? "" : "" };
              }}
            >
              <div
                className="flex items-center  py-[6.5px] nav_link  w-full pl-[18px] rounded-[6px] group"
                onClick={() => handleDropdownClick("reports")}
              >
                <div className="flex items-center gap-[16px]">
                  <BsBarChartLineFill
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                    Reports
                  </p>
                </div>
                {activeDropdown === "reports" ? (
                  <IoIosArrowForward
                    size={15}
                    className="rotate-90 text-[#625f6e]  group-hover:text-white "
                  />
                ) : (
                  <IoIosArrowForward
                    size={15}
                    className="rotate-0 text-[#625f6e]  group-hover:text-white "
                  />
                )}
              </div>
            </NavLink>
            {activeDropdown === "reports" && (
              <div className="w-full transition duration-300 ease-out flex flex-col gap-[8px] bg-[#E5F0F7]">
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <AiOutlinePieChart
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/invoices">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      All Invoices
                    </p>
                  </NavLink>
                </div>
                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <AiOutlineLineChart
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/reports">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      SMS History
                    </p>
                  </NavLink>
                </div>

                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <GrTransaction
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/transactions">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      Transactions
                    </p>
                  </NavLink>
                </div>

                <div className="flex w-full items-center gap-[16px] pl-[18px] py-[6.5px] group nav_link  rounded-[6px]">
                  <GrTransaction
                    size={17}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <NavLink to="/admin/eom-records">
                    <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                      EOM Records
                    </p>
                  </NavLink>
                </div>
              </div>
            )}
          </div>

          {/*----------------- theme Customizer------------- */}
          <div className="flex items-center flex-col gap-[16px]  rounded-[6px]    w-[223px] cursor-pointer ">
            <NavLink
              to="/admin/customizer"
              className="w-full"
              style={({ isActive }) => {
                return { backgroundColor: isActive ? "" : "" };
              }}
            >
              <div className="flex items-center  py-[6.5px] nav_link  w-full pl-[18px] rounded-[6px] group">
                <div className="flex items-center gap-[16px]">
                  <AiOutlineFormatPainter
                    size={18}
                    className=" text-[#625f6e]   group-hover:text-white "
                  />
                  <p className="text-[#625f6e]  text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                    Theme Customizer
                  </p>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
        {/* right section */}
        <div className="lg:ml-[280px] container   mx-auto md:mr-[40px]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
